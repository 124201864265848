html {
  height: 100%;
  box-sizing: border-box;
}

body {
  font: normal 100% / normal Arial, Helvetica, sans-serif;
  margin: 0;
  font-size: 16px;
  height: 100%;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: inherit;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 20px;
  border: 0;
}

*::-webkit-scrollbar-corner {
  background: inherit;
}

.dashboard-wrapper {
  position: relative;
  display: flex;
  margin-top: 48px;
  flex: 1 1 auto;
  flex-direction: row;
  overflow: hidden;
  box-sizing: border-box;
}

.shortcut-menu {
  width: 56px;
  overflow-y: auto;
  border-radius: 0px !important;
}

.tab-content {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
}

div#app {
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

nav {
  margin: 10px !important;
}
nav p, nav a {
  font-size: 12px !important;
}

.navbar {
  position: fixed !important;
  box-shadow: none !important;
  z-index: 1100 !important;
}
.navbar > .toolbar {
  padding-left: 0px;
  padding-right: 0px;
}

.navbar-menu-button {
  margin-left: 5px !important;
}

#app-title {
  text-transform: none;
  margin-left: 18px;
}